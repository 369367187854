import React from 'react';
import SEO from '../components/helpers/SEO';
import Base from '../templates/Base';

const NotFoundPage = () => (
  <Base>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
  </Base>
);

export default NotFoundPage;
